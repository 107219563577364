export default {
  name: 'ozgunmustafa',
  contactEmail: 'ozzgunmustafa@gmail.com',
  landingPageName: 'Mustafa Özgün',
  landingPageDescription:
    "Designer and front-end developer. It'ss my portfolio page",
  projects: [
    {
      id: 1,
      title: 'Edupick',
      description: 'Frontend Development',
      images: ['/edupick-mockup.jpg', '/edupick-mockup.jpg'],
      url: '#',
    },
    {
      id: 2,
      title: 'yds.net',
      description: 'Frontend Development',
      images: ['/yds-mockup.jpg', '/yds-mockup.jpg'],
      url: 'https://yds.net/',
    },
    {
      id: 3,
      title: 'Puanegitim',
      description: 'Frontend Development',
      images: ['/puanegitim.png', '/puanegitim.png'],
      url: 'https://puanegitim.com/',
    },
    {
      id: 4,
      title: 'Akindil',
      description: 'Frontend Development',
      images: ['/akindil-mockup.png', '/akindil-mockup.png'],
      url: 'https://akindil.com/',
    },
    {
      id: 5,
      title: 'Kocaer Textile',
      description: 'Frontend Development',
      images: ['/kocaertextile-mockup.jpg', '/altinbilezik-mockup.jpg'],
      url: 'https://kocaertextile.com/',
    },
    {
      id: 6,
      title: 'altinbilezik',
      description: 'Frontend Development',
      images: ['/altinbilezik-mockup.jpeg', '/altinbilezik-mockup.jpeg'],
      url: 'https://cv.ozgunmustafa.com/works/altinbilezik-new/',
    },
    {
      id: 7,
      title: 'Treso',
      description: 'Frontend Development',
      images: ['/treso-mockup.jpg', '/treso-mockup.jpg'],
      url: 'https://treso.com.tr/',
    },
    {
      id: 8,
      title: 'Reflex Mama',
      description: 'Frontend Development',
      images: ['/reflex-mockup.jpg', '/reflex-mockup.jpg'],
      url: 'https://www.reflexmama.com/',
    },
    {
      id: 9,
      title: 'Elegant Menswear',
      description: 'Frontend Development',
      images: ['/elegant-menswear.png', '/elegant-menswear1.png'],
      url: 'https://elegantmenswear.ozgunmustafa.vercel.app/',
    },
    {
      id: 10,
      title: 'Herkesgurme App Design',
      description: 'Design',
      images: ['/herkesgurme-app-design.png'],
      url: 'https://www.behance.net/gallery/103089499/HerkesGurme-App-Interface-Design',
    },
    {
      id: 11,
      title: 'hizliresim.com',
      description: 'Backend, Web Development',
      images: ['/hizliresim.png'],
      url: 'https://hizliresim.com/',
    },
    {
      id: 12,
      title: 'paukampus.com',
      description: 'Design and Development',
      images: ['/paukampus.png', 'https://www.youtube.com/watch?v=AoMAUy8_R_c'],
      url: 'https://www.youtube.com/watch?v=AoMAUy8_R_c',
    },
  ],
  techStack: {
    
  },

  contact: {
    LINKEDIN: 'https://linkedin.com/in/ozgunmustafa',
    GITHUB: 'https://github.com/ozgunmustafa',
    CODEPEN: 'https://codepen.io/ozgunmustafa',
    BEHANCE: 'https://www.behance.net/ozgunmustafa',
    EMAIL: 'ozzgunmustafa@gmail.com',
    PHONE: '905073861125',
    DRIBBBLE: 'https://dribbble.com/ozgunmustafa',
  },
};
